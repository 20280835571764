<template>
  <div class="page">
    <div class="box1">
      <div class="main_box">
        <div class="ttl">工人教育解决方案</div>
        <div class="sub_ttl">有效、有趣、简单、精准的建筑工人知识教育平台</div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
    </div>
    <div class="box5">
      <div class="title">传统工人教育存在什么问题？</div>
      <div class="main_box" flex="main:justify">
        <div class="col c1">
          <div class="info">
            <div class="ttl">培训形式主义</div>
            <div class="desc">
              长编大论工人无法吸收教育知识，刚培训完就忘记，集体培训没有针对性，工人无法学习到想要的知识。
            </div>
          </div>
        </div>
        <div class="col c2">
          <div class="info">
            <div class="ttl">没有学习动力</div>
            <div class="desc">
              采用工人现场签到，学习效率低下，人员无法集中注意力，人力物力投入成本较高。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="title">我们产品的优势</div>
      <div class="main_box" flex="main:justify">
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_education@3x.png"
            alt="建设产业知识平台"
          />
          <div class="ttl">建设产业知识平台</div>
          <div class="desc">
            集技术教育、安全教育、疾病防控、工具使用等知识为一体
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_video.png"
            alt="丰富的教育资源库"
          />
          <div class="ttl">丰富的教育资源库</div>
          <div class="desc">
            收录行业专业、新颖、常用的教育视频资源，人人都能成为技术达人
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_education.png"
            alt="有趣的教育形式"
          />
          <div class="ttl">有趣的教育形式</div>
          <div class="desc">
            1分钟短视频，一边刷屏一边学技术，营造趣味学习氛围
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_learning.png"
            alt="精准精细化学习"
          />
          <div class="ttl">精准精细化学习</div>
          <div class="desc">选学习类型，选工种标签，快速匹配精准学习圈</div>
        </div>
      </div>
    </div>
    <div class="box4">
      <div class="title">如何激励工人学习</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box4.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box4.slides"
            :key="idx"
            @click="box4.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
        <div class="phone_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box4.swiper = sw)"
            @slideChange="() => (box4.crtIndex = box4.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box4.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box4.crtIndex === i ? 'z-crt' : '']"
              @click="box4.swiper.slideTo(i)"
              v-for="(item, i) in box4.slides"
              :key="i"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="trial_bar">
      <div class="main_box" flex="main:justify cross:center">
        <div class="ttl">为您打造一套数智工程管理解决方案</div>
        <div class="btn" @click="showTrialModal">申请体验</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/Footer.vue";

const baseUrl = `${process.env.BASE_URL}public/img/手机截图`;

const box4 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "短视频",
      desc: "解决传统质量教育走流程，排拆学习，低效学习的痛",
      pic: `${baseUrl}/工人教育_2_1.png`,
    },
    {
      ttl: "奖学金",
      desc: "刷视频，抢红包，一边学习一边赚钱，上下班两不误",
      pic: `${baseUrl}/工人教育_2_2.png`,
    },
    {
      ttl: "碎片成长",
      desc: "上班实操，闲时成长，早日成长，早日当班组长",
      pic: `${baseUrl}/工人教育_2_3.png`,
    },
    {
      ttl: "学习排位",
      desc: "企业根据学习排位，树一批并奖励一批优秀的建设者",
      pic: `${baseUrl}/工人教育_2_4.png`,
    },
  ],
};

export default {
  components: {
    Footer,
  },
  data() {
    return {
      box4,
    };
  },
  methods: {
    ...mapActions(["showTrialModal"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.box1 {
  background: url("~@/assets/bg/banner 5.jpg") no-repeat center center;
  background-size: cover;
  height: 32rem;
  overflow: hidden;

  .ttl {
    margin-top: 13.8rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .sub_ttl {
    margin-top: 1.1rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }

  .btn {
    margin-top: 3rem;
    display: block;
    width: 6.6rem;
    height: 2rem;
    background-color: #2574ff;
    border-radius: 0.4rem;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 2rem;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box5 {
  padding: 7rem 0;
  background-color: #fff;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 3rem;

    .col {
      flex-shrink: 0;
      position: relative;
      width: 33rem;
      height: 20rem;
      color: #fff;
      background-size: cover;
      background-position: center center;

      &.c1 {
        background-image: url("~@/assets/bg/培训形式主义.jpg");
      }

      &.c2 {
        background-image: url("~@/assets/bg/没有学习动力.jpg");
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.6)
        );
      }

      .info {
        position: relative;
        z-index: 1;
        margin: 12rem 2.5rem 0;
      }

      .ttl {
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        line-height: 2.25rem;
      }

      .desc {
        margin-top: 0.8rem;
        font-size: 1rem;
        color: #fff;
        line-height: 1.5rem;
      }
    }
  }
}

.box2 {
  height: 32rem;
  overflow: hidden;
  background-color: #f0f1f6;

  .title {
    margin-top: 7rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 5.4rem;
  }

  .col {
    width: 15rem;
    text-align: center;

    .icon {
      display: block;
      width: 5rem;
      height: 5rem;
      margin: auto;
    }

    .ttl {
      margin-top: 0.7rem;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      color: #1a1a1a;
      line-height: 1.5rem;
    }

    .desc {
      margin: 0.6rem auto 0;
      width: 12.5rem;
      font-size: 0.7rem;
      text-align: center;
      color: #9a9a9a;
      line-height: 1.2rem;
    }
  }
}

.box4 {
  overflow: hidden;
  background-color: #fff;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 7rem;
    width: 56.35rem;
    height: 31rem;
    background: url("~@/assets/bg/装饰背景2.png") no-repeat right center;
    background-size: auto 100%;
    padding-right: 4.5rem;
    box-sizing: border-box;
  }
}

.trial_bar {
  background-color: #f0f1f6;
  padding: 6rem 0 1.6rem;

  .main_box {
    background: url("~@/assets/bg/申请体验背景.png") no-repeat center center;
    background-size: cover;
    height: 7rem;
  }

  .ttl {
    margin-left: 4rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.5rem;
    letter-spacing: 0.15rem;
  }

  .btn {
    margin-right: 4rem;
    width: 7rem;
    height: 2.4rem;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #0078f0;
    line-height: 2.4rem;
    cursor: pointer;

    &:hover {
      background-color: #dce8ff;
    }
  }
}

.main_box {
  width: $mainWidth;
  margin: auto;
}

.row_tabs {
  width: 24rem;

  .item {
    margin-bottom: 2rem;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.z-crt {
      .num {
        opacity: 1;
      }

      .ttl {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-top: 0;
      }
    }

    .num {
      width: 1.7rem;
      height: 1.7rem;
      line-height: 1.7rem;
      text-align: center;
      background-color: #0078f0;
      border-radius: 0.6rem;
      opacity: 0.2;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      margin-right: 0.8rem;
    }

    .ttl {
      font-size: 0.9rem;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 1.35rem;
      margin-top: 0.2rem;
    }

    .desc {
      margin-top: 0.4rem;
      font-size: 0.7rem;
      color: #9a9a9a;
      line-height: 1.1rem;
    }
  }
}

.phone_swp {
  position: relative;
  width: 14.9rem;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30.55rem;
    z-index: 1;
    background: url("~@/assets/bg/1手机边框.png") center center no-repeat;
    background-size: contain;
  }

  .swp {
    width: 13rem;
    height: 28.15rem;
    margin: 0.9rem auto 0;

    .pic {
      display: block;
      width: 100%;
    }
  }

  .pagination {
    margin-top: 1.8rem;

    .dot {
      width: 0.6rem;
      height: 0.15rem;
      opacity: 0.4;
      background-color: #2574ff;
      border-radius: 0.1rem;
      cursor: pointer;
      transition: opacity 0.3s;
      margin-right: 0.4rem;

      &:last-child {
        margin-right: 0;
      }

      &.z-crt {
        opacity: 1;
      }
    }
  }
}
</style>
